@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -20px;
    align-items: flex-start;
}

.col,
.col1,
.col2,
.col3,
.col4,
.col5,
.col6,
.col7,
.col8,
.col9,
.col12 {
    padding: 0 8px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 16px;
}

.col1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
}

.col3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
}

.col6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.col7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
}

.col9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
}

.col12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

.header {
    display: block;
    margin-top: 16px;
    color: var(--font-color, #3B3E3F);
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    width: 100%;
    /* 156.25% */
}

@media (max-width: 800px) {

    .col,
    .col1,
    .col2,
    .col3,
    .col4,
    .col5,
    .col6,
    .col7,
    .col8,
    .col9,
    .col12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.grey {
    color: #929292;
}
.red {
    color: #FB3B3B;
}

.loader {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}