@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css');

@font-face {
  font-family: "Bebas Neue Cyrillic";
  src: url("./static/Bebas\ Neue_Cyrillic.ttf");
}

body {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  padding: 50px 30px;
}