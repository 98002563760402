@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');

.labeled_textarea {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 8px 8px 16px;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid #CFCFCF;
    background: #FFF;
    min-height: 56px;

    &.disabled {
        background-color: hsl(0, 0%, 95%);
        border-color: hsl(0, 0%, 90%);
    }

    label {
        color: #999;
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px
    }

    textarea {
        width: 100%;
        color: var(--2-d-font-color, #222);
        font-family: 'Inter';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        border: none;
        padding: 0;
    }

    textarea:focus-visible {
        outline: none;
    }

    span {
        min-height: 42px;
    }

    &.errorBorder {
        border-color: #FB3B3B;
    }
}

.red_color_text {
    color: #FB3B3B;
    margin-top: 10px;
    display: inline-block;
}
.required {
    color: #FB3B3B;
}