.button {
    border: none;
    background: none;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0;
    cursor: pointer;
    span {
        font-size: 14px;
    }
}
.required {
    color: #FB3B3B;
}