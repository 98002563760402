.DateInput {
    position: relative;
    input {
        width: 100%;
        padding: 8px 8px 8px 16px!important;
        color: var(--2-d-font-color, #222);
        font-family: 'Inter';
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        border-radius: 5px;
        border: 1px solid #CFCFCF;
        background: #FFF;
        min-height: 56px;
        
        &.errorBorder {
            border-color: #FB3B3B;
        }
        
        &.disabled {
            background-color: hsl(0, 0%, 95%);
            border-color: hsl(0, 0%, 90%);
        }
      }
}
.Calendar {
    position: absolute;
    z-index: 100;
    left: 0;
    top: calc(100% + 10px);
    border: 1px solid #CFCFCF;
}
@media (max-width:1000px) {
    .Calendar {
        width: 100%;
        div {
            width: 100%;
            padding: 0;
        }
    }
}
.red_color_text {
    color: #FB3B3B;
    margin-top: 10px;
    display: inline-block;
  }
.required {
    color: #FB3B3B;
}