.btn_box {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
        background: #FB3B3B;
        border: none;
        border-radius: 7px;
        color: #FFF;
        font-family: "Bebas Neue Cyrillic";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 1px;
        padding: 10px 40px;
    }
}