.citizenship .react_select__option:nth-child(1) {
    font-weight: bolder;
}
.country .react_select__option:nth-child(1), 
.country .react_select__option:nth-child(2), 
.country .react_select__option:nth-child(3) {
    font-weight: bolder;
}
.kz_region .react_select__option:nth-child(1) {
    font-weight: bold !important;
}
.region .react_select__option:nth-child(1), .region .react_select__option:nth-child(2) {
    font-weight: bold !important;
}
.city .react_select__option:nth-child(1) {
    font-weight: bold !important;
}
.district .react_select__option:nth-child(1) {
    font-weight: bolder;
}