@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@font-face {
    font-family: "Bebas Neue Cyrillic";
    src: url("../../static/Bebas\ Neue_Cyrillic.ttf");
}

.form {
    padding: 48px 64px;

    @media (max-width: 800px) {
        padding: 0;
    }
    .form_title {
        margin-bottom: 56px;
        h1 {
            text-align: center;
            text-transform: uppercase;
            color: #000;
            font-size: 24px;
            font-style: normal;
            line-height: 25px;
            letter-spacing: 1.2px;
        }
        span {
            display: block;
            text-align: center;
            font-size: 20px;
            color: #5795FD;
            font-weight: 400;
            line-height: 25px;
        }
    }

    h2,
    h3 {
        color: #000;
        font-size: 16px;
        font-style: normal;
        line-height: 25px;
        text-transform: uppercase;
        letter-spacing: 1.2px;
    }

    h3 {
        margin-top: 55px;
    }

    .redButton {
        cursor: pointer;
        border: none;
        border-radius: 7px;
        background: #FB3B3B;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        width: 100%;
        padding: 19px;
    }

    .redButton:disabled {
        background: #FDB1B1;
    }

}

.loadImageBox {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn_box {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
        background: #FB3B3B;
        border: none;
        border-radius: 7px;
        color: #FFF;
        font-family: "Bebas Neue Cyrillic";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 1px;
        padding: 10px 40px;
    }
}
.required {
    color: #FB3B3B;
}

.closeModalBtnBox {
    display: flex;
    justify-content: flex-end;
  
    img {
      cursor: pointer
    }
}