.title {
    color: #929292;
    font-size: 12px;
}
.messengersCheck {
    display: flex;
    gap: 32px;
    margin-top: 8px;
    span {
        color: #929292;
        font-size: 14px;
    }
}