.loaderBox {
    text-align: center;
}
.vacancies_box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 500px;
  
    .collapsePanel {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #FFFF;
      justify-content: space-around;
      border: 1px solid #dee2e6;
      border-left: 5px solid #39c7f6;
  
      &_header {
        width: 100%;
  
        button {
          background: #FFFF;
          border: none;
          outline: none;
          box-shadow: none;
          display: flex;
          align-items: flex-start;
          gap: 16px;
  
          @media (max-width: 800px) {
            padding: 10px;
          }
        }
      }
  
      &_item {
        display: flex;
        flex-direction: column;
        min-width: 20%;
  
        @media (max-width: 800px) {
          &.first_hidden_mobile {
            display: none;
          }
        }
  
        @media (max-width: 600px) {
          &.second_hidden_mobile {
            display: none;
          }
        }
  
        &__grey {
          color: #929292
        }
  
        &__black {
          color: #000
        }
      }
  
      >div {
        width: 100%;
      }
  
      &_section {
        padding: 20px;
  
        pre {
          font-family: 'Inter';
          white-space: break-spaces;
        }
  
        &_btn_box {
          width: 100%;
          display: flex;
          justify-content: center;
  
          button {
            background: #FB3B3B;
            border: none;
            border-radius: 7px;
            color: #FFF;
            font-family: "Bebas Neue Cyrillic";
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 1px;
            padding: 10px 40px;
          }
        }
      }
    }
  
  }
  
  .noVacanciesMessageBlock {
    display: flex;
    gap: 45px;
    justify-content: center;
  
    @media (max-width: 998px) {
      flex-wrap: wrap;
    }
  
    .noVacanciesMessage {
      display: flex;
      flex-direction: column;
      gap: 13px;
      margin-top: 40px;
  
      b {
        color: #3B3E3F;
        font-size: 36px;
        font-weight: 500;
      }
  
      p {
        width: 247px;
        font-size: 16px;
        font-weight: 400;
      }
    }
  
  }