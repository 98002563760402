@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');

label {
    color: #999;
    font-size: 13px;
    line-height: 12px;
}
.react_select {
    box-shadow: none !important;
    border: 1px solid #CFCFCF;
    border-radius: 5px !important;
    font-family: "Inter";
    font-style: normal;
    min-height: 56px;
    outline: none!important;
    font-family: "Inter";

    &__value-container {
        color: #999 !important;
        padding: 8px 8px 8px 16px;
    }

    &__control {
        outline: 0 !important;
        border: none!important;
        border-color: transparent!important;
        color: #999;
        font-family: "Inter";
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        background: #FFF;
        box-sizing: content-box !important;
        min-height: inherit!important;

        &:hover,
        &--is-focused {
            box-shadow: none !important;
            border-color: #CFCFCF !important;
            outline: none !important;
        }
    }

    &__option {
        font-family: "Inter";
        border: none;
        color: #222222;
        background: #ffff;

        &--is-selected {
            color: #3E85FD !important;
            background-color: #f6fbfe !important;
        }
        &--is-focused {
            background: #f6fbfe!important;
            background-color: #f6fbfe !important;
        }

        &:hover {
            background: #f6fbfe;
        }
    }

    &__placeholder {
        color: #999 !important;
    }
    &__single-value {
        margin-left: 0!important;
        margin-right: 0!important;
    }
    span {
        color: #FB3B3B !important;
    }
}

.errorBorder {
    border-color: #FB3B3B !important;
}

.red_color_text {
    color: #FB3B3B;
    margin-top: 10px;
    display: inline-block;
}
.required {
    color: #FB3B3B;
}
.disabled {
    background-color: hsl(0, 0%, 95%)!important;
}