@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');

.Avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    flex-direction: column;

    .image {
        position: relative;
        width: 164px;
        height: 164px;
        border: 1px solid #CFCFCF;
        border-radius: 7px;
        overflow: hidden;
        cursor: pointer;

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .dropdown {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0;
            background: #000000;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: center;

            button {
                background: none;
                outline: none;
                border: none;
                padding: 0;
                display: flex;
            }

            transition: opacity 400ms;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    > button {
        width: 164px;
        height: 164px;
        border-radius: 7px;
        border: 1px dashed #CFCFCF;
        background: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 10px;
        cursor: pointer;

        &.errorBorder {
            border-color: #FB3B3B;
        }

        span {
            text-align: center;
            font-family: "Inter";
            font-size: 14px;
            font-weight: 400;
            &.red, .red {
                color: #FB3B3B;
            }
        }

        .placeholder {
            font-size: 12px;
            color: #999999;
        }

        input {
            display: none;
        }
    }

}

.red_color_text {
    color: #FB3B3B;
    margin-top: 10px;
    display: inline-block;
}