.agreeBox {
    display: flex;
    align-items: center;

    button {
        display: flex;
        align-items: center;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
    }

    span {
        margin: 0 21px 0 14px;
    }

    img {
        cursor: pointer;
    }
}