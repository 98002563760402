.container {
    position: relative;
    margin: auto;
    padding: 0 5%;
}

@media only screen and (max-width:1000px) {
    .container {
        padding: 0
    }
}

@media only screen and (max-width:768px) {
    .buttonsContainer {
        row-gap: 1.387266355140187rem;
        grid-template-areas: "right" "left";
        margin-top: 4rem
    }
}

.buttonsContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 2.43056rem;
    align-items: center;
    margin-top: 50px;
}

@media only screen and (max-width:768px) {
    .buttonsContainer {
        grid-template-areas: "left" "right";
        grid-template-columns: 100%;
        align-content: center
    }
}

.redButton {
    cursor: pointer;
    border: none;
    border-radius: 7px;
    background: #FB3B3B;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
    padding: 19px;
}

.blueButton {
    cursor: pointer;
    border: none;
    border-radius: 7px;
    background: #5795fd;
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
    padding: 19px;
}

.hollowButton {
    cursor: pointer;
    border: none;
    border-radius: 7px;
    background: #FFF;
    color: #b3b3b3;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    width: 100%;
    padding: 19px;
}

.closeModalBtnBox {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;

    img {
        cursor: pointer
    }
}

.agreementText {
    margin-bottom: 5em;

    h1 {
        text-align: center;
        margin-bottom: 1em;
    }
}