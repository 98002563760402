@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');

label {
  color: #999;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px
}
.labeled_input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  border-radius: 5px;
  border: 1px solid #CFCFCF;
  background: #FFF;
  min-height: 56px;

  input {
    width: 100%;
    padding: 8px 8px 8px 16px!important;
    color: var(--2-d-font-color, #222);
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    border: none;
    border-radius: 5px;
    min-height: 56px;
  }

  input[type=date]::-webkit-calendar-picker-indicator {
    z-index: 100;
    position: absolute;
    width: 20px;
    height: 100%;
    right: 12px;
    top: 22px;
    opacity: 0;
    cursor: pointer;
  }

  input:focus-visible {
    outline: none;
  }

  span {
    min-height: 42px;
  }

  img {
    position: absolute;
    right: 12px;
    cursor: pointer;
  }

  &.errorBorder {
    border-color: #FB3B3B;
  }

  &.disabled {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
  }
}

.red_color_text {
  color: #FB3B3B;
  margin-top: 10px;
  display: inline-block;
}
.required {
  color: #FB3B3B;
}
.hide {
  display: none;
}