@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400&display=swap');

label {
  color: #999;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px
}
.labeled_input {
    position: relative!important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 8px 8px 16px;
    gap: 8px;
    border-radius: 5px!important;
    border: 1px solid #CFCFCF!important;
    background: #FFF!important;
    min-height: 56px;
    padding-left: 16px !important;
    width: 100%!important;
    color: var(--2-d-font-color, #222);
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;

  &:focus {
    outline: none;
    box-shadow: none!important;
  }

  span {
    min-height: 42px;
  }

  &.errorBorder {
    border-color: #FB3B3B!important;
  }

  &.disabled {
    background-color: hsl(0, 0%, 95%)!important;
    border-color: hsl(0, 0%, 90%)!important;
  }
}

.red_color_text {
  color: #FB3B3B;
  margin-top: 10px;
  display: inline-block;
}
.required {
  color: #FB3B3B;
}
.hide {
  display: none;
}